export default [
  {
    name: 'Dan',
    quote: `Apex helps me build a complete cloud based, highly automated workflow and an AI chatbot to disrupt my business segment. In the meantime, let me focus on my current business. The improved productivity from the product demo is extraordinary, very exciting toward launch date. I won't have enough time or capital to do it by myself. Apex service is a real game changer.`,
  },
  // {
  //   name: 'Mark',
  //   quote: `I keep coming back for more foundation knowledge. Save me a ton of time searching. Instead of solving the problem for me, the tuts taught me to lead myself to the solutions.`,
  // },
  // {
  //   name: 'Dan',
  //   quote: `Awesome tutorials, please keep up with the great work.`,
  // },
  // {
  //   name: 'Dolores',
  //   quote: `Best classes ever. These should be made official in universities.`,
  // },
]
