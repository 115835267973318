import React, { Component } from 'react'
import Helmet from 'react-helmet'
import GitHubButton from 'react-github-btn'
import { graphql, Link } from 'gatsby'
import Layout from '../layout'
import PostListing from '../components/PostListing'
import ProjectListing from '../components/ProjectListing'
import SimpleListing from '../components/SimpleListing'
import SEO from '../components/SEO'
import config from '../../data/SiteConfig'
import projects from '../../data/projects'
import speaking from '../../data/speaking'
import podcasts from '../../data/podcasts'
import quotes from '../../data/quotes'
import tania from '../../src/images/logos_small/Group_7.png'

import facebook from '../../content/images/facebook-logo.png'
import viettel from '../../content/images/viettel.jpeg'
import vnpt from '../../content/images/vnpt.png'
import calmisland from '../../content/images/calmisland.png'
import mbbanklogo from '../../content/images/mbbanklogo.png'
import amo from '../../content/images/amo.png'
import gg from '../../content/images/giggrafter.jpeg'

const clientLogoLinks = [
  {logo: viettel, link: 'https://viettel.com.vn/'},
  {logo: vnpt, link: 'https://vnpt.com.vn/'},
  {logo: calmisland},
  {logo: mbbanklogo, link: 'https://www.mbbank.com.vn/?lang=EN'},
  {logo: amo, link: 'https://oneamo.com/'},
  {logo: gg, link: 'https://www.giggrafter.com/'},
]

export default class Index extends Component {
  render() {
    const { data } = this.props

    const latestPostEdges = data.latest.edges
    const popularPostEdges = data.popular.edges
    const projectPostEdges = data.project.edges

    return (
      <Layout>
        <Helmet title={`Software solutions and services | ${config.siteTitle}`} />
        <SEO />
        <div className="container">
          <div className="lead">
            <div className="elevator">
              <h1>{`Apex Software`}</h1>
              <p>
              Your one stop shop for all software  <a href="/services">services</a> needs, brought to you from <a href="https://en.wikipedia.org/wiki/Silicon_Valley" target="_blank" rel="noopener noreferrer">Silicon Valley</a>. Our combined SF bay area & offshore teams offer top-notch software quality with significantly lower cost (<a href="/how-it-works">how it works</a>). So you can <span style={{color: 'red'}}>focus on your innovations and business</span>.
              </p>
            </div>
            <div className="newsletter-section">
              <img src={tania} className="newsletter-avatar" alt="SESV" />
              <div>
                <h3>Get a quote</h3>
                <p>
                  Free 15 minutes talk to discover how much (time & investment) you can save.
                </p>
                <a className="button" href="https://m.me/102299125232381" target="_blank">
                  Chat now
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="container front-page">
          <section className="section">
            <h2>
              Latest Projects
              <Link to="/categories/project" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={projectPostEdges} />
          </section>
          
          <section className="section">
            <h2>
              Latest posts
              <Link to="/blogs" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={latestPostEdges} />
          </section>

          {/* <section className="section">
            <h2>
              Most Popular
              <Link to="/categories/popular" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={popularPostEdges} />
          </section> */}

          <section className="section">
            <h2>{`Clients`}</h2>
          <div className="container client-div">
          {clientLogoLinks.map(c => (
          <a href={c.link ? c.link : '/'} target="_blank">
          <img
            src={c.logo}
            target="_blank"
            rel="noopener noreferrer"
            className="our-client-img"
            alt="Apex Software Silicon Valley Facebook Page"
            />
          </a>))}
          </div>
            <div className="quotations">
              {quotes.map(quote => (
                <blockquote className="quotation" key={quote.name}>
                  <p>{quote.quote}</p>
                  <cite>— {quote.name}</cite>
                </blockquote>
              ))}
            </div>
          </section>



        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 10
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 7
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    project: allMarkdownRemark(
      limit: 7
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Project" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`
